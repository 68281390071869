<template>
	<div class="container">
		<bread-crums :nesting="['Dental Care', 'Dental Implants']"></bread-crums>
		<intro button-text="Contact Us" button-link="/contact" title="Dental Implants" :content="paragraphs"/>
		<div class="pgcontent">
			<LazyYoutube src="https://www.youtube-nocookie.com/embed/RABIU6bmzOs"></LazyYoutube>
			<div v-for="card in cards" :key="card.title" class="card">
				<h2>{{card.title}}</h2>
				<p>{{card.content}}</p>
			</div>
      <div class="section" v-for="section in sections" :key="section.title">
        <p>{{ section }}</p>
      </div>
			<div class="table">
				<price-table :procedure="Procedures['categories'][1]"></price-table>
			</div>
		</div>      
	</div>
</template>

<script>
import Intro from '../components/Intro.vue'
import PriceTable from '../components/PriceTable.vue'
import  Procedures  from "../assets/json/procedures.json";
import BreadCrums from '../components/BreadCrums.vue';
import { LazyYoutube } from "vue-lazytube";

export default {
	name: 'GeneralDentisty',
	components: {
		Intro,
		PriceTable,
		BreadCrums,
		LazyYoutube
	},
	data(){
		return{
			Procedures,
			paragraphs: [
					"A fixed option of a surgically placed biocompatible (titanium) abutment to replace missing teeth."
			],
			cards:[
			],
      sections:[
        "A dental implant may be a suitable treatment for missing teeth (single or multiple), ill-fitting dentures for the top or bottom jaw. We provide this treatment in-house and this may be a permanent treatment options for you where suitable. Your implant surgeon will discuss the stages of treatment from the diagnostics, planning and surgical treatment itself, followed up by long-term care and maintenance. In brief, a biocompatible titanium abutment is surgically placed into the jaw-bone and allowed to integrate. After a healing period of approximately 12 to 16 weeks, the abutment will then be restored with an implant crown. Implants have evolved a long way in dentistry in the last 20 years and no other treatment will feel or look as close to a natural tooth."
      ]
		}
	}
}
</script>

<style scoped>
	.pgcontent {
		background-color: #FFF;
		padding: 1.5rem;
	}
	h2{
		width: 95%;
		color: var(--primary)
	}
	@media screen and (min-width: 375px){
		.pgcontent{
			padding: 1.5rem 2.5rem;
		}
	}
	@media screen and (min-width: 768px){
		.pgcontent{
			padding: 1.5rem 6rem;
		}	
	}
	@media screen and (min-width: 1024px) {
		.vlt-wrapper{
			max-width: 56%;
		}
		.section{
			max-width: 40%;
			margin: 0 auto;
		}
		p{
			margin: 0;
			padding: 0;
		}
		.container{
			padding-top: 1rem;
		}
		.pgcontent{
			display: flex;
			flex-wrap: wrap;
			padding: 2rem var(--padding1);
			justify-content: center;
		}
		.card{
			max-width: 50%;			
		}
		.table{
			width: 100%;
		}
		
	}
	@media screen and (min-width: 1440px) {		
		.pgcontent{
			padding: 2rem var(--padding2);
		}    
	}
	@media screen and (min-width: 1750px){
		.pgcontent{
			padding: 2rem 4rem;
		}    
	}
</style>